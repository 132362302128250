<template>
  <div class="card-panel">
    <div class="card-content" v-if="cardType.length">
      <div class="header">
        <div class="header-left">
          <Carousel v-model="carouselIndex" dots="none" @on-change="handleCarouselChange" loop>
            <CarouselItem v-for="item in cardType" :key="item.name">
              <div class="card-pic" :style="{ backgroundImage: `url(${item.tp})` }"></div>
            </CarouselItem>
          </Carousel>
          <div class="header-content">
            <div class="card-info">
              <div class="card-name">{{ selectedCard.name }}</div>
              <div class="card-tip">有效期：{{ cardOverview.yxq }}，剩余有效期：{{ cardOverview.syyxq }}</div>
            </div>
            <div class="card-overview">
              <i-circle :percent="cardOverview.sjjd" dashboard stroke-color="#fdad00">
                <div class="card-circle">
                  <p>{{ cardOverview.sjjd }}%</p>
                  <p>时间进度</p>
                </div>
              </i-circle>
              <i-circle :percent="cardOverview.xhjd" dashboard stroke-color="#fdad00">
                <div class="card-circle">
                  <p>{{ cardOverview.xhjd }}%</p>
                  <p>消耗进度</p>
                </div>
              </i-circle>
            </div>
          </div>
        </div>
        <div class="header-right">
          <p class="header-title">卡系寄存清单</p>
          <table class="table">
            <tr>
              <th></th>
              <th style="width: 40%"></th>
              <th>已消耗次数</th>
              <th>剩余次数</th>
              <th>服务总次数</th>
            </tr>
            <tr v-for="(item, index) in cardOverview.kxjcqd" :key="index">
              <th>{{ item.name }}</th>
              <td>
                <Progress :percent="(item.zcs === 0 ? 0 : (item.xh / item.zcs).toFixed(2)) * 100" />
              </td>
              <td>{{ item.xh }}</td>
              <td>{{ item.sy }}</td>
              <td>{{ item.zcs }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="card-content bg-white pt-20px">
      <div class="header">
        <div class="header-btn">
          <Button type="info">卡系记录</Button>
        </div>
        <div class="header-btn">
          <!-- <Button type="success">开卡</Button> -->
        </div>
      </div>
      <div class="table-panel">
        <Table :columns="dataColumns" :data="dataList"></Table>
        <div>
          <span class="records">共 {{ dataTotal }} 条记录</span>
        </div>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page
              :total="dataTotal"
              :current="dataCurrentPage"
              :page-size="cardQuery.size"
              @on-change="changeDataPage"
            ></Page>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import cardService from '@/services/cardService';

export default {
	data() {
		return {
			carouselIndex: 0,
			selectedCard: {},
			cardQuery: {
				page: 1,
				size: 20,
				kxlx_id: '',
				member_id: '',
			},
			dataColumns: [
				{ title: '所属门店', key: 'ssmd', align: 'center' },
				{
					title: '卡系名称',
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								style: {
									color: '#ff8400',
								},
							},
							params.row.kxmc,
						);
					},
				},
				{ title: '卡系有效期', align: 'center', key: 'kxyxq' },
				{
					title: '卡系时间',
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								style: {
									color: '#4495f0',
								},
							},
							params.row.kxyxq,
						);
					},
				},
				{
					title: '卡系状态',
					key: 'kxzt',
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								style: {
									color:
										params.row.kxzt === 2 ? '#4cae32' : params.row.kxzt === 3 ? '#f07844' : 'red',
								},
							},
							params.row.kxzt_name,
						);
					},
				},
				{ title: '续卡时长', key: 'xksj', align: 'center' },
				{ title: '续卡员工', key: 'xkyg', align: 'center' },
				{ title: '续卡时间', key: 'xksj', align: 'center' },
				{ title: '审批情况', key: 'spzt_name', align: 'center' },
				{
					title: '操作',
					align: 'center',
					render: (h) => {
						const actions = [
							h(
								'Button',
								{
									attrs: {
										type: 'error',
										size: 'small',
									},
									style: {
										marginRight: '5px',
									},
								},
								'删除',
							),
						];
						return actions;
					},
				},
			],
			cardType: [],
			dataList: [],
			dataTotal: 0,
			dataCurrentPage: 1,
			cardOverview: {
				kxjcqd: [],
				xhjd: 0,
				sjjd: 0,
			},
		};
	},
	created() {
		cardService.getCardType().then((data) => {
			this.cardType = data;
			this.selectedCard = data[0];
			this.getCardRecords();
			this.getDepositList();
		});
	},
	methods: {
		getCardRecords() {
			this.cardQuery.kxlx_id = this.selectedCard.id;
			this.cardQuery.member_id = this.$route.params.member_id;
			cardService.getCardRecords(this.cardQuery).then((data) => {
				this.dataTotal = data.row_size;
				this.dataCurrentPage = data.cur_page;
				this.dataList = data.list;
			});
		},
		getDepositList() {
			cardService
				.memberDepositList({
					kxlx_id: this.selectedCard.id,
					member_id: this.$route.params.member_id,
				})
				.then((data) => {
					this.cardOverview = data;
				});
		},
		changeDataPage(page) {
			this.cardQuery.page = page;
			this.getCardRecords();
		},
		handleCarouselChange(old, index) {
			this.selectedCard = this.cardType[index];
			this.cardQuery.page = 1;
			this.getCardRecords();
			this.getDepositList();
		},
	},
};
</script>

<style lang="less" scoped>
.card-content {
  margin-bottom: 20px;
}
.header {
  background-color: #fff;
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  button {
    margin-left: 10px;
  }
  &-left {
    flex: 1;
    padding: 10px 20px;
    border-right: #c8c8c8 solid 1px;
    display: flex;
    .ivu-carousel {
      width: 402px;
    }
  }
  &-right {
    flex: 1;
    padding: 10px 20px;
  }
  &-title {
    font-size: 18px;
    font-weight: bold;
  }
  &-content {
    flex: 1;
    margin-left: 15px;
  }
  &-action {
    span {
      margin: 0 15px;
    }
  }
  &-btn {
    margin-right: 20px;
  }
}
.bg-white {
  background-color: #fff;
}
.pt-20px {
  padding-top: 20px;
}
.table-panel {
  padding: 0 20px;
}
.card-pic {
  height: 255.2px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
.card-name {
  font-size: 18px;
  font-weight: bold;
  color: #fdad00;
  margin-top: 15px;
}
.card-tip {
  margin-top: 5px;
  color: #666;
}
.card-overview {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 200px;
}
table {
  font-size: 16px;
  width: 100%;
  td {
    padding: 5px 10px;
    text-align: center;
  }
  th {
    color: #8c8c8c;
    font-size: 14px;
  }
}
.card-circle {
  p:first-child {
    font-size: 22px;
    margin-bottom: 10px;
  }
}
</style>
